import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Container } from '@chakra-ui/react'
import Layout from "../components/Layout"
import Footer from '../components/Footer'
import SEO from "../components/SEO"
import { H1 } from '../theme/Headings'
import { spacing, breakpoints, colors } from '../theme/themeAlopex'
import PlainText from '../components/CaseStudies/PlainText'
import PlainImage from '../components/CaseStudies/PlainImage'
import ImageText from '../components/CaseStudies/ImageText'
import Columns from '../components/CaseStudies/Columns'
// gsap
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const K2Page = ({ data }) => {
  // gsap's scrollTrigger for fade-up
  const gsapFadeUp = (csBlocks) => {
    for (let i = 0; i < csBlocks.length; ++i) {
      gsap.from(csBlocks[i], {
        scrollTrigger: {
          trigger: csBlocks[i],
          start: 'top 75%',
          // marker: true
        },
        duration: 1,
        y: 50,
        opacity: 0,
      })
    }
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const csBlocks = document.querySelectorAll('.csBlock')
      gsapFadeUp(csBlocks)
    }
  }, [])

  return (
    <Layout>
      <SEO title={`K2 Aviation Branding & Website - ${data.site.siteMetadata.title}`} noIndex />
      <Container maxWidth={breakpoints.xl} pt={spacing.header}>
        <H1 color={colors.brand.slate} textAlign="center" mt={spacing.header} mb={spacing.groups}>K2 Aviation Branding & Website</H1>
      </Container>
      {/** BLOCK 01 */}
      <ImageText
        heading="Brief"
        body="The K2 Aviation brand is synonymous with Denali flight-seeing and a major adventure destination internationally. K2 Aviation
        came to Alopex after a frustrating round of trying to have a website developed without a clear visual direction. In desperate need
        of rebranding and digital pick-me-up, K2 Aviation contracted Alopex to revamp their representation."
        img={data.redrum.childImageSharp.fluid}
        color={colors.brand.lightGray}
        overlayColor="red"
        overlayOpacity="0"
      />
      {/** BLOCK 02 */}
      <PlainImage img={data.glacierwalk.childImageSharp.fluid} alt="Glacier landing walk" />
      {/** BLOCK 03 */}
      <PlainText
        color={colors.brand.slate}
        bgColor="#eee"
        heading="Brand"
        body="The K2 Aviation logo has undergone a number of revisions over the company’s lifetime. Complicating the matter, their sister company,
        Rust’s Aviation, would also need to be rebranded in a similar way. What resulted was a brand system that evokes the grandeur and excitement
        of flying around the tallest mountain in the world. The brand mark and fonts went through dozens of revisions with multiple meetings—after
        all, this brand is on the tails of a fleet of bright red airplanes. The logo package for K2 Aviation defines their brand colors, fonts and
        usage and includes file types for absolutely every type of application."
      />
      {/** BLOCK 04 */}
      <Columns
        blockLeft={
          <PlainImage img={data.logos.childImageSharp.fluid} alt="Brand exploration" size="small" />
        }
        blockRight={
          <PlainImage img={data.befafter.childImageSharp.fluid} alt="Before & After" size="small" />
        }
      />
      {/** BLOCK 05 */}
      <PlainText
        color={colors.brand.slate}
        bgColor="#eee"
        heading="Website"
        body="Traffic to <a href='https://flyk2.com/' target='_blank' rel='noopener noreferrer'>Flyk2.com</a> is significant year-round and comprised
        of international visitors who come to Alaska for spectacular experiences. A major advantage that K2 Aviation has is that their line of
        business attracts phenomenal photographers and videographers, which gave Alopex a lot to work with. The website incorporates media as an
        integral part to communicating the adventures, and highly optimized tools like the weather tool and interactive map engages users and get
        them excited about their K2 Aviation flight-see. FlyK2.com is built on WordPress and integrates a number of tools to help drive traffic to
        the website and increase conversions—including Yoast for SEO and Pantheon for powerful hosting."
      />
      {/** Block 06 */}
      <Columns
        blockLeft={
          <PlainImage img={data.home.childImageSharp.fluid} alt="Home page" size="small" />
        }
        blockRight={
          <PlainImage img={data.internal.childImageSharp.fluid} alt="Internal page" size="small" />
        }
      />
      {/** BLOCK 07 */}
      <PlainImage img={data.mtnmap.childImageSharp.fluid} />
      {/** BLOCK 08 */}
      <ImageText
        heading="Results"
        className="lastBlock"
        body="Since publishing the new FlyK2 experience and brand, <a href='https://flyk2.com/' target='_blank' rel='noopener noreferrer'>FlyK2.com</a>
        has seen a significant fall in bounce rates, an increase in page engagement (how long people spend on pages) and over a doubling of conversion
        rates through website bookings. The brand update has culminated in much stronger positioning among K2’s competition and recognizability of the
        brand has been shored-up."
        img={data.mtnblue.childImageSharp.fluid}
        color={colors.brand.lightGray}
        overlayColor="#153e70"
        overlayOpacity="0.6"
      />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query k2Page {
    site {
      siteMetadata {
        title
      }
    }
    redrum: file(relativePath: { eq: "casestudies/k2/K2-Background-06.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    glacierwalk: file(relativePath: { eq: "casestudies/k2/ALOPEX_K2-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logos: file(relativePath: { eq: "casestudies/k2/K2-LOGO-VERSIONS-01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    befafter: file(relativePath: { eq: "casestudies/k2/K2_Insta-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    home: file(relativePath: { eq: "casestudies/k2/Alopex_K2_home.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    internal: file(relativePath: { eq: "casestudies/k2/Alopex_K2_Glacier.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mtnmap: file(relativePath: { eq: "casestudies/k2/ALOPEX_K2-2-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mtnblue: file(relativePath: { eq: "casestudies/k2/Alopex_K2_Results.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default K2Page
